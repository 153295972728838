import { default as _91slug_93zR4sq1KuIdMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as indexA20HgJRyo1Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guides5vxtA0vgGwMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_936X93DbXPQ2Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as index7vQ6f8q4VbMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbackGL3Nd9naHnMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45usPErajlL9l2Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callback1HRi7C9WECMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as mergeHLuRYncjDJMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as comprehensiveLuFYrbys6mMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as indexNDimdrny7eMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_93uNWGpZkwR1Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as detailsoQmdWwb2pjMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsv5OiVLePXXMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexZDr0iBknESMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexorcLP4CUA1Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticefIQkUv1fwhMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintsxClG9wzZIGMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usiOwfcKpQR4Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_93fEl6ucNPVIMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as indexYekhC4OMioMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_93GH2ltr7EhvMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93FToJsCMsutMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settingszYQYeDhzzfMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexbNaddFE1KwMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as index1wR9eEnxl2Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as indexAh8lkYPBStMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexxNTj8XzdQzMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as indexb20xU5SNCeMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as detailsZn62DiEjD6Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue?macro=true";
import { default as _91step_93EvRVAlqiyxMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/[step].vue?macro=true";
import { default as additional_45detailsGgQwwRtg18Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/additional-details.vue?macro=true";
import { default as completionlLoMMdcFYaMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/completion.vue?macro=true";
import { default as _91step_93yNz65mtnDTMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/step/[step].vue?macro=true";
import { default as lic_45detailsylTMn2rd7KMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue?macro=true";
import { default as rebateqtPLvY1VT3Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue?macro=true";
import { default as indexdYd0qPQVEuMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_93Jrpf5O32A3Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsvDxyjFHbRUMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45youNjgFRsolmkMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_934UBocJe0gzMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indextXiTNQLjwJMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_936Gzwx5kPiZMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as indexHumjiqpYcfMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45passwordtEgLTMMqpHMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as _91step_93aJjM0QA1SiMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expiredGKXfTSTqxjMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexTHGvSqBDJYMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexI0emg9XM8nMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as errorKrpNBC26KJMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as index0af8whtd9lMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45bg7zdvJj9VLMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chatUGgX5q13d6Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as indexW1nnreVablMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addelQc1AFejzMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupP5BgNir9ZSMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45saleOagAj4itwNMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as indexfBrtS9OFHYMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93pozMD8U9oWMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as details1ytajoDXIWMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as indexta99SINoItMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45youupcuKPdNAxMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as indexp2Q43JdpbgMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexfs90nQmNXnMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as indexvF3TvxjGgFMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indextdKWtZM0kdMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93G1pLvKZ1yCMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_93JkuJCVFFstMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmation7O8dCBkEXlMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as detailsIMBXjKvTyaMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexlzLbp2fGFgMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultsOmtI7ycCjeMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45you8cKtrf0U3tMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_93wzwTLFlEIJMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as indexXMSohd0N8VMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexIf2iGvcEvwMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45ratesYuxP5i6G8nMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93MUSO4ihZduMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsD4BwXrcEgLMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45youhcSC7P7kSSMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as indexlA5eANhK2KMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/index.vue?macro=true";
import { default as confirmationlFe9Exx6BeMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailsocLfmT0Q43Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexorSwZfwdLtMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as indexVw61g2tZ53Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as addv9NeoZAVJ7Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youK33Odd7ovAMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as indexpUZxI88aKlMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as successetMFhkGigoMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_93yaLPOxTKt4Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as detailsqPsKGFmnjkMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as loginjSFQ1Kc9JrMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93KpHyISPRISMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_936T4djIAJOyMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexT3WQjjFVadMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipsaIzbIwpk6wMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as indexWw5GrDEXXxMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as post_45sale_45thank_45youtXDrEprvQiMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_934pf3UKkRyOMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93D3apExytxtMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93LfrCK2G8DLMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policyoxOkHo3e6KMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as registerzuYl4lT3DcMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordB6xWqboEr0Meta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as terms_45of_45useFZUbR3ZaxjMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verifyv3vZonJsUoMeta } from "/codebuild/output/src996951124/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callbackGL3Nd9naHnMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93fEl6ucNPVIMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingszYQYeDhzzfMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexbNaddFE1KwMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: index1wR9eEnxl2Meta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexAh8lkYPBStMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexxNTj8XzdQzMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    meta: indexb20xU5SNCeMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product?/details",
    meta: detailsZn62DiEjD6Meta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-vertical-step",
    path: "/dashboard/vault/:product?/form/:vertical()/:step()",
    meta: _91step_93EvRVAlqiyxMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-vertical-additional-details",
    path: "/dashboard/vault/:product?/form/:vertical()/additional-details",
    meta: additional_45detailsGgQwwRtg18Meta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-vertical-completion",
    path: "/dashboard/vault/:product?/form/:vertical()/completion",
    meta: completionlLoMMdcFYaMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-form-vertical-step-step",
    path: "/dashboard/vault/:product?/form/:vertical()/step/:step()",
    meta: _91step_93yNz65mtnDTMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product?/lic-details",
    meta: lic_45detailsylTMn2rd7KMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product?/rebate",
    meta: rebateqtPLvY1VT3Meta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordtEgLTMMqpHMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginjSFQ1Kc9JrMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerzuYl4lT3DcMeta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordB6xWqboEr0Meta || {},
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src996951124/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]